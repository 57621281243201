import React, { Component } from 'react';
import { Button } from './';
import { validateEmail } from "../helpers/validation";
import symbols from '../media/svg-symbols.svg';

export class StaffMemberEditor extends Component {
    constructor(props) {
        super(props)
        this.submitting = false;
        this.state = {
            changes: {},
            emailValid: true
        }
    }

    handleChange = e => {
        let { name, value } = e.target;
        // Converting to number if changing role
        if (name === "role" && value !== "Select Role") value = parseInt(value);
        // Checking for email changes
        if (name === "email") {
            if (value) {
                this.setState({
                    changes: Object.assign({}, this.state.changes, { [name]: value }),
                    emailValid: validateEmail(value)
                })
            } else {
                this.setState({
                    changes: Object.assign({}, this.state.changes, { [name]: value }),
                    emailValid: true
                })    
            }
        } else {
            this.setState({
                changes: Object.assign({}, this.state.changes, { [name]: value })
            })
        }
    }

    onSubmit = () => {
        this.submitting = true;
        const { editStaffMember, member } = this.props;
        let changes = { ...this.state.changes };
        // Removing empty values
        for (var key in changes) {
            if (changes[key] === "") delete changes[key]
        }
        // Getting splitting name into first and second.
        if (changes.name) {
            let name = changes.name.split(" ");
            changes.fName = name[0];
            changes.lName = name[1] ? name[1] : "";
            delete changes.name;
        }
        
        editStaffMember(member.id, changes, () => {
            this.submitting = false;
        })
    }

    canSubmit() {
        let result = true;
        const { changes, emailValid } = this.state;
        const changeKeys = Object.keys(changes);
        if (!changeKeys.length) result = false;
        changeKeys.forEach(k => {
            if (!changes[k]) result = false;
            if (k === "role" && changes[k] === "Select Role") result = false;
        })
        if (!emailValid) result = false;
        return result;
    }

    render() {
        const { changes, emailValid } = this.state;
        const { closeEditor } = this.props
        const { fName, lName, email, role, userId, disabled, id } = this.props.member;
        
        return (
            <form className={"user user--edit"} onSubmit={e => {
                e.preventDefault();
                if (!this.submitting) this.onSubmit()

            }}>
                <span className="user__close" onClick={closeEditor}>
                    <svg className="icon-close"><use xlinkHref={`${symbols}#icon-cross`}></use></svg>
                </span>
                <div className="form-inner">
                    <div className="row two-field">
                        <div className="field">
                            <input
                                type="text"
                                name="name"
                                value={changes.name !== undefined ? changes.name : `${fName} ${lName}`}
                                onChange={this.handleChange} />
                        </div>
                        <div className="field">
                            <input
                                type="text"
                                name="email"
                                value={changes.email !== undefined ? changes.email : (email || "")}
                                onChange={this.handleChange} />
                            {(!emailValid) && <span className="field-validation-error">Please enter a valid email address</span>}
                        </div>
                    </div>
                    <div className="row two-field">
                        <div className="field">
                            <input
                                type="text"
                                name="userId"
                                value={changes.userId !== undefined ? changes.userId : userId}
                                onChange={this.handleChange} />
                        </div>
                        <div className="field">
                            <select
                                name="role"
                                value={changes.role !== undefined ? changes.role : role}
                                onChange={this.handleChange}
                            >
                                <option>Select Role</option>
                                <option value="2">Center Manager</option>
                                <option value="3">Tester</option>
                            </select>
                        </div>
                    </div>
                    <div className="row">
                        {disabled
                            ?
                            <Button primary={true} full={true} text="Enable User" onClick={() => {
                                if (window.confirm("Are you sure you want to enable this user?")) {
                                    this.props.editStaffMember(id, { disabled: 0 })
                                }
                            }} />
                            :
                            <Button destructive={true} full={true} text="Disable User" onClick={() => {
                                if (window.confirm("Are you sure you want to disable this user?")) {
                                    this.props.editStaffMember(id, { disabled: 1 })
                                }
                            }} />
                        }
                    </div>
                    <div className="row">
                        {this.canSubmit() && <Button full={true} type="submit" text="Submit" loading={this.submitting} />}
                    </div>
                </div>
            </form>
        )
    }
};