import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Loading } from './';

const generateButtonClass = ({
  primary, secondary, destructive, block, loading, full
}) => {
  const cls = ['btn'];
  if (primary) cls.push('btn--primary');
  if (secondary) cls.push('btn--secondary');
  if (destructive) cls.push('btn--destructive');
  if (block) cls.push('btn--block');
  if (loading) cls.push('btn--loading');
  if (full) cls.push('btn--full')
  return cls.join(' ');
};

export const Button = (props) => {
  const {
    text, type, onClick, loading, disabled
  } = props;
  const handleClick = (e) => {
    if (onClick) {
      onClick(e);
    }
  };

  return (
    <button onClick={handleClick} className={generateButtonClass(props)} type={type || 'button'} disabled={disabled || loading}>
      {loading ? (
        <Loading grey />
      ) : (
        <Fragment>
          {text}
        </Fragment>
      )}
    </button>
  );
};

export const LinkButton = (props) => {
  const { text, to } = props;
  return (
    <Link to={to} className={generateButtonClass(props)}>
      {text}
    </Link>
  );
};
