import React, { Component } from 'react';
import { Button } from './';

import symbols from '../media/svg-symbols.svg';

export class FileUpload extends Component {
	constructor(props) {
		super(props);
		this.state = {
			fileName: '',
			fileData: '',
			error: '',
			loading: false
		};
	}

	selectFile = (e) => {
		const { value, files } = e.target;
		const { allowedTypes } = this.props;

		if (!this.validateFileType(value, allowedTypes)) {
			this.setState({
				fileName: '',
				fileData: '',
				error: `Invalid file type, allowed types: ${allowedTypes.join(', ')}`,
				loading: false
			});
			return;
		}

		this.setState({
			fileName: value,
			fileData: files[0],
			error: '',
			loading: false
		});
	}

	validateFileType(fileName, allowedTypes) {
		if (!allowedTypes || !allowedTypes.length) {
			return true;
		}
		let ext = /(\.[^.]+)$/.exec(fileName);
		if (!ext || allowedTypes.indexOf(ext[1].toLowerCase()) === -1) {
			return false;
		}
		return true;
	}

	onSubmit = (e) => {
		const { fileData } = this.state;
		const { onSubmit } = this.props;

		if (!fileData) {
			this.setState({
				error: 'Please select a file'
			});
			return false;
		}

		this.setState({
			loading: true
		});

		onSubmit(fileData, (error) => {
			this.setState({
				fileName: '',
				fileData: '',
				error,
				loading: false
			});
		});
	}

	render() {
		const { fileName, error, loading } = this.state;
		let name = 'Select file to upload';
		if (fileName) {
			const parts = fileName.split('\\');
			name = parts[parts.length - 1];
		}

		return (
			<div className={`row file-upload ${fileName ? 'has-file' : ''}`}>
				<label className="field">
					<input type="file" value={fileName} onChange={this.selectFile} />
					<span className="dummy-input">
						<svg className={`icon-${fileName ? 'file' : 'upload'}`}><use xlinkHref={`${symbols}#icon-${fileName ? 'file' : 'upload'}`}></use></svg>
						{name}
					</span>
					{error && <span className="field-validation-error">{error}</span>}
				</label>
				<Button text="Upload" onClick={this.onSubmit} loading={loading} />
			</div>
		);
	}
}