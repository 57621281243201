import React from 'react';

import { Error, Loading } from '.';

import symbols from '../media/svg-symbols.svg';

export const CentreHeader = ({ centre, id }) => {
  if (centre.loading) {
    return <Loading />;
  }

  if (centre.error) {
    return <Error error={centre.error} />;
  }

  if (!centre) {
    return <Error error="Centre not found" />;
  }

  return (
    <h1><svg className="icon-house"><use xlinkHref={`${symbols}#icon-house`}></use></svg> {centre.name}</h1>
  );
}
