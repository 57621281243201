import { userConstants } from '../actions';

const initialState = {};

export default function (state = initialState, action) {
	switch (action.type) {
		case userConstants.LOGIN_REQUEST:
			return { loading: true };
		case userConstants.LOGIN_SUCCESS:
			return action.user;
		case userConstants.LOGIN_FAILURE:
			return { error: action.error };
		case userConstants.LOGIN_EXISTING_SUCCESS:
			return action.user;
		case userConstants.LOGOUT_SUCCESS:
			return {};
		default:
			return state;
	}
}
