import React from 'react';

import symbols from '../media/svg-symbols.svg';

export const Error = ({ error }) => {
  return (
    <div className="page-error">
      <svg className="icon-error"><use xlinkHref={`${symbols}#icon-error`}></use></svg>
      {error}
    </div>
  );
}
