import React from 'react';

import { Centre, NewCentre, Error, Loading } from '.';

export const CentreList = ({ centres, adminUser }) => {
    if (centres.loading) {
        return <Loading pad />;
    }

    if (centres.error) {
        return <Error error={centres.error} />;
    }

    return (
        <div className="centre-list">
            {Object.values(centres)
                .sort((a, b) => {
                    let aDisabled = Boolean(a.disabled);
                    let bDisabled = Boolean(b.disabled);

                    if (aDisabled === bDisabled) {
                        return 0;
                    } else if (aDisabled && !bDisabled) {
                        return 1;
                    } else {
                        return -1;
                    }
                })
                .map(centre => <Centre key={centre.id} centre={centre} />)}
            {adminUser && <NewCentre />}
        </div>
    );
}
