import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import { centreActions } from '../actions';

import { Button } from './';

import symbols from '../media/svg-symbols.svg';

class NewCentre extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      addressLine1: '',
      addressLine2: '',
      town: '',
      county: '',
      postcode: '',
      submitted: false,
      submitting: false,
      formToggled: false
    }
  }

  toggleForm = (e) => {
    const { name, formToggled } = this.state;
    this.setState({
      name: formToggled ? '' : name,
      formToggled: !formToggled
    });
  }

  handleChange = (e) => {
    const { formToggled } = this.state;
    const { name, value } = e.target;
    const toggled = name === 'name' ? !!name : formToggled
    this.setState({ [name]: value, formToggled: toggled });
  }

  onSubmit = (e) => {
    e.preventDefault();
    const { createCentre } = this.props;
    const { name, addressLine1, addressLine2, town, county, postcode } = this.state;
    
    this.setState({
      submitted: true
    });

    if (!name || !addressLine1 || !town || !postcode) {
      return false;
    }
    
    const address = _.compact([addressLine1, addressLine2, town, county, postcode]).join('\n');
    
    createCentre(name, address, () => {
      this.setState({
        name: '',
        addressLine1: '',
        addressLine2: '',
        town: '',
        county: '',
        postcode: '',
        submitted: false,
        submitting: false,
        formToggled: false
      });
    });    
  }

  render() {
    const { name, addressLine1, addressLine2, town, county, postcode, submitted, submitting, formToggled } = this.state;
    const iconName = formToggled ? 'icon-minus' : 'icon-plus';

    return (
      <form className={`centre-list__item centre-list__item--new ${formToggled ? 'active' : ''}`} onSubmit={this.onSubmit}>
        <label className="centre-list__item__heading">
          <svg className="icon-house"><use xlinkHref={`${symbols}#icon-house`}></use></svg>
          <input type="text" name="name" value={name} onChange={this.handleChange} placeholder="Add a new test centre" />
          <span className="btn btn--secondary" onClick={this.toggleForm}>
            <svg className={iconName}><use xlinkHref={`${symbols}#${iconName}`}></use></svg>
          </span>
        </label>
        <div className="form-inner">
          <div className="row two-field">
            <div className="field">
              <input type="text" name="addressLine1" value={addressLine1} onChange={this.handleChange} placeholder="Address line 1" />
              {(submitted && !addressLine1) && <span className="field-validation-error">Address Line 1 is required</span>}
            </div>
            <div className="field">
              <input type="text" name="addressLine2" value={addressLine2} onChange={this.handleChange} placeholder="Address line 2" />
            </div>
          </div>
          <div className="row two-field">
            <div className="field">
              <input type="text" name="town" value={town} onChange={this.handleChange} placeholder="Town/City" />
              {(submitted && !town) && <span className="field-validation-error">Town is required</span>}
            </div>
            <div className="field">
              <input type="text" name="county" value={county} onChange={this.handleChange} placeholder="County" />
            </div>
          </div>
          <div className="row two-field">
            <div className="field">
              <input type="text" name="postcode" value={postcode} onChange={this.handleChange} placeholder="Postcode" />
              {(submitted && !postcode) && <span className="field-validation-error">Postcode is required</span>}
            </div>
          </div>
          <div className="row">
            <Button type="submit" text="Submit" loading={submitting} />
          </div>
        </div>
      </form>
    );
  }  
}

export default connect(null, {
  createCentre: centreActions.createCentre
})(NewCentre);