import React from 'react';
import { Route, Redirect } from 'react-router-dom';

export const PrivateRoute = ({ component: Component, user, ...rest }) => {

	return (
		<Route
			{...rest}
			render={props => (
				user && user.data && user.data.user && !user.data.user.disabled
					? <Component {...props} />
					: <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
			)}
		/>
	);
}