export function getChartDataByUserId(tests, getChartData) {
	// Concat all data.
	let allData = [];
	Object.keys(tests).forEach(date => {
		allData = allData.concat(tests[date]);
	});
	// Get all the unique users in the dataset.
	let userIds = [...new Set(allData.map(t => t.userId))];
	// Create a dataset for each user.
	return userIds.map(userId => {
		let data = {}
		Object.keys(tests).forEach(date => {
			data[date] = tests[date].filter(t => t.userId === userId);
		});
		return {
			name: userId,
			data: getChartData(data),
			lineWidth: 4
		}
	});
}