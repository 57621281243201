import React, { Component, Fragment } from 'react';
import _ from 'lodash';

import { getTestOutcomeStatus } from '../helpers/ragStatuses';
import { secondsToDuration } from '../helpers/formatters';

import { TestDetail, DonutChart } from '.';

function calculateTestOutcomes(tests) {
	return _.groupBy(tests, 'result');
}

function calculateFailures(staff) {
	let staffTotals = {
		avg_fail_percentage: 0,
		avg_test_time: 0,
		avg_veh_age: 0,
		fail_body: 0,
		fail_brakes: 0,
		fail_buses: 0,
		fail_lamps: 0,
		fail_noise: 0,
		fail_road_wheels: 0,
		fail_seatbelt_installation: 0,
		fail_seatbelt_supplementary: 0,
		fail_spedometer: 0,
		fail_steering: 0,
		fail_suspension: 0,
		fail_tyres: 0,
		fail_veh_id: 0,
		fail_visibility: 0,
		tests_done: 0
	}

	if (!staff || !staff.length) {
		return [];
	}
	const nationalAverage = _.find(staff, { userId: 'national' });
	const siteAverage = _.find(staff, { userId: 'site' });
    const staffAverages = _.filter(staff, (member) => (member.userId !== 'site' && member.userId !== 'national'));
	const staffCount = staffAverages.length;
	if (!staffCount) {
		return [siteAverage, nationalAverage];
	}

	staffAverages.forEach((average) => {
		staffTotals.avg_fail_percentage += average.avg_fail_percentage * average.tests_done;
		staffTotals.avg_test_time += average.avg_test_time * average.tests_done;
		staffTotals.avg_veh_age += average.avg_veh_age * average.tests_done;
		staffTotals.fail_body += average.fail_body * average.tests_done;
		staffTotals.fail_brakes += average.fail_brakes * average.tests_done;
		staffTotals.fail_buses += average.fail_buses * average.tests_done;
		staffTotals.fail_lamps += average.fail_lamps * average.tests_done;
		staffTotals.fail_noise += average.fail_noise * average.tests_done;
		staffTotals.fail_road_wheels += average.fail_road_wheels * average.tests_done;
		staffTotals.fail_seatbelt_installation += average.fail_seatbelt_installation * average.tests_done;
		staffTotals.fail_seatbelt_supplementary += average.fail_seatbelt_supplementary * average.tests_done;
		staffTotals.fail_spedometer += average.fail_spedometer * average.tests_done;
		staffTotals.fail_steering += average.fail_steering * average.tests_done;
		staffTotals.fail_suspension += average.fail_suspension * average.tests_done;
		staffTotals.fail_tyres += average.fail_tyres * average.tests_done;
		staffTotals.fail_veh_id += average.fail_veh_id * average.tests_done;
		staffTotals.fail_visibility += average.fail_visibility * average.tests_done;
		staffTotals.tests_done += average.tests_done;
	});

	staffTotals.avg_fail_percentage = staffTotals.avg_fail_percentage / staffTotals.tests_done;
	staffTotals.avg_test_time = staffTotals.avg_test_time / staffTotals.tests_done;
	staffTotals.avg_veh_age = staffTotals.avg_veh_age / staffTotals.tests_done;
	staffTotals.fail_body = staffTotals.fail_body / staffTotals.tests_done;
	staffTotals.fail_brakes = staffTotals.fail_brakes / staffTotals.tests_done;
	staffTotals.fail_buses = staffTotals.fail_buses / staffTotals.tests_done;
	staffTotals.fail_lamps = staffTotals.fail_lamps / staffTotals.tests_done;
	staffTotals.fail_noise = staffTotals.fail_noise / staffTotals.tests_done;
	staffTotals.fail_road_wheels = staffTotals.fail_road_wheels / staffTotals.tests_done;
	staffTotals.fail_seatbelt_installation = staffTotals.fail_seatbelt_installation / staffTotals.tests_done;
	staffTotals.fail_seatbelt_supplementary = staffTotals.fail_seatbelt_supplementary / staffTotals.tests_done;
	staffTotals.fail_spedometer = staffTotals.fail_spedometer / staffTotals.tests_done;
	staffTotals.fail_steering = staffTotals.fail_steering / staffTotals.tests_done;
	staffTotals.fail_suspension = staffTotals.fail_suspension / staffTotals.tests_done;
	staffTotals.fail_tyres = staffTotals.fail_tyres / staffTotals.tests_done;
	staffTotals.fail_veh_id = staffTotals.fail_veh_id / staffTotals.tests_done;
    staffTotals.fail_visibility = staffTotals.fail_visibility / staffTotals.tests_done;
    
    // console.log("staffTotals:", staffTotals);

	return [
		staffTotals,
		siteAverage,
		nationalAverage,
	];
}

export class TestOutcomes extends Component {
	render() {
		const { staffAverages, tests, index, selectedIndex, selectTestDetail } = this.props;
		const open = selectedIndex === index;

		const averages = calculateFailures(staffAverages.month);
		const users = staffAverages.month ?
			staffAverages.month
				.filter(s => !["site", "national"].includes(s.userId))
				.map(s => s.userId)
			: [];

		const testOutcomes = calculateTestOutcomes(tests.month);
		const donutData = [
			{ name: 'Fail', y: testOutcomes.FAILED ? testOutcomes.FAILED.length : 0, color: '#C1272D' },
			{ name: 'Aborted', y: testOutcomes.ABORTED ? testOutcomes.ABORTED.length : 0, color: '#F99F00' },
			{ name: 'Pass', y: testOutcomes.PASSED ? testOutcomes.PASSED.length : 0, color: '#7AC943' }
		];

		// Calculate the fail percentages for all passes.
		const nationalFailPercentage = averages[averages.length - 1] ?
			averages[averages.length - 1].avg_fail_percentage : 0;
		const currentFailPercentage = (donutData[0].y / (donutData[0].y + donutData[1].y + donutData[2].y)) * 100;

		return (
			<Fragment>
				<TestDetail
					title="Test Outcomes"
					value={<DonutChart data={donutData} />}
					status={getTestOutcomeStatus(currentFailPercentage, nationalFailPercentage)}
					open={open}
					onClick={() => selectTestDetail(index)}
				/>
				{open && <TestVariationDetails users={users} data={averages} />}
			</Fragment>
		)
	}
}

const TestVariationDetails = ({ users, data }) => {

	return (
		<section className="centre-expanded-detail">
			{users.length ?
				<h2>Test outcomes for {users.join(", ")}.</h2>
				:
				<h2>Test outcomes.</h2>
			}
			<table>
				<thead>
					<tr>
						<th></th>
						{users.length ? <th>Selected staff</th> : null}
						<th>Site</th>
						<th>National</th>
					</tr>
				</thead>
				<tbody>
					<TableRow label="Tests done" name="tests_done" suffix="" data={data} />
					<TableRow label="Average vehicle age" name="avg_veh_age" suffix="yrs" data={data} dps={1} />
					<TableRow label="Average test time" name="avg_test_time" suffix="" data={data} isTime />
					<TableRow label="Tests failed" name="avg_fail_percentage" suffix="%" data={data} dps={1} />
					<TableRow label="Body, chassis, structure" name="fail_body" suffix="%" data={data} dps={1} />
					<TableRow label="Brakes" name="fail_brakes" suffix="%" data={data} dps={1} />
					<TableRow label="Buses and coaches supplementary tests" name="fail_buses" suffix="%" data={data} dps={1} />
					<TableRow label="Identification of the vehicle" name="fail_veh_id" suffix="%" data={data} dps={1} />
					<TableRow label="Lamps, reflectors and electrical equipment" name="fail_lamps" suffix="%" data={data} dps={1} />
					<TableRow label="Noise, emissions and leaks" name="fail_noise" suffix="%" data={data} dps={1} />
					<TableRow label="Road Wheels" name="fail_tyres" suffix="%" data={data} dps={1} />
					<TableRow label="Seat belt installation check" name="fail_seatbelt_installation" suffix="%" data={data} dps={1} />
					<TableRow label="Seat belts and supplementary restraint systems" name="fail_seatbelt_supplementary" suffix="%" data={data} dps={1} />
					<TableRow label="Speedometer and speed limiter" name="fail_spedometer" suffix="%" data={data} dps={1} />
					<TableRow label="Steering" name="fail_steering" suffix="%" data={data} dps={1} />
					<TableRow label="Suspension" name="fail_suspension" suffix="%" data={data} dps={1} />
					<TableRow label="Tyres" name="fail_tyres" suffix="%" data={data} dps={1} />
					<TableRow label="Visibility" name="fail_visibility" suffix="%" data={data} dps={1} />
				</tbody>
			</table>
		</section>
	);
}

const TableRow = ({ label, name, suffix, isTime, dps, data }) => {
	let nationalData = data[data.length - 1];
	let status;
	if (data[0] && nationalData)
		status = getTestOutcomeStatus(data[0][name], nationalData[name]);
	return (
		<tr>
			<td>{label}</td>
			{data.map((item, index) => {

				let val = item[name] || 0;
				let showStatus;

				if (item.userId === "national" || index === 0) {
					showStatus = true;
				} else {
					showStatus = false;
				}

				if (isTime) {
					val = secondsToDuration(val * 60);
				}
				if (dps) {
					val = val.toFixed(dps);
				}

				return <td
					className={showStatus ? status : null}
					key={index}><strong>{`${val}${suffix}`}</strong>
				</td>;
			})}
		</tr>
	);
}