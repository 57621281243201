import React, { Component } from 'react';
import ReactQuill from 'react-quill';

import { Button } from './';

import 'react-quill/dist/quill.snow.css';

export class NewReport extends Component {
	constructor(props) {
		super(props);

		const { report } = this.props;

		this.state = {
			comment: report ? report.comment : '',
			submitted: false,
			submitting: false
		}
	}

	handleChange = (value) => {
		this.setState({ comment: value });
	}

	onSubmit = (e) => {
		e.preventDefault();
		const { createReport, updateReport, report } = this.props;
		const { comment } = this.state;

		this.setState({
			submitted: true
		});

		if (!comment) {
			return false;
		}

		this.setState({
			submitting: true
		});

		if (report) {
			updateReport(report.id, comment);
		} else {
			createReport(comment);
		}
	}

	render() {
		const { comment, submitted, submitting } = this.state;

		return (
			<form className="new-report" onSubmit={this.onSubmit}>
				<div className="row">
					<div className="field">
						<ReactQuill name="comment" value={comment} onChange={this.handleChange} placeholder="Create new report" theme="snow" />
						{(submitted && !comment) && <span className="field-validation-error">Please enter a comment</span>}
					</div>
				</div>
				<Button type="submit" text="Submit" loading={submitting} />
			</form>
		);
	}
};