import { combineReducers } from 'redux';

import AlertsReducer from './alerts';
import CentreReducer from './centre';
import CentresReducer from './centres';
import UserReducer from './user';
import SettingsReducer from './settings';

const rootReducer = combineReducers({
	alerts: AlertsReducer,
	centre: CentreReducer,
	centres: CentresReducer,
	user: UserReducer,
	settings: SettingsReducer
});

export default rootReducer;
