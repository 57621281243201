import { settingsConstants } from '../actions';

const initialState = {
	splitUserData: false
};

export default function (state = initialState, action) {
	switch (action.type) {
		case settingsConstants.TOGGLE_SPLIT_USER_DATA:
			return { splitUserData: !state.splitUserData };
		default:
			return state;
	}
}
