export const settingsConstants = {
	TOGGLE_SPLIT_USER_DATA: 'TOGGLE_SPLIT_USER_DATA',
};

export const settingsActions = {
	toggleSplitUserData() {
		return dispatch => dispatch({
			type: settingsConstants.TOGGLE_SPLIT_USER_DATA
		});
	}
};
