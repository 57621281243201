import React, { PureComponent } from 'react';
import _ from 'lodash';

export class Select extends PureComponent {
  render() {
    const { name, value, options, onChange, className } = this.props;

    return (
      <select id={name} name={name} value={value} onChange={onChange} className={className}>
        {_.map(options, option => <option key={option.value} value={option.value}>{option.label}</option>)}
      </select>
    );
  }
}
