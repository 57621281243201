import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import { getTestVariationStatus } from '../helpers/ragStatuses';
import { TestDetail, LineChart } from '.';
import { getChartDataByUserId } from '../helpers/misc';

function calculateAverageVariation(staff) {
	let staffTotals = {
		avg_fail_percentage: 0,
		fail_body: 0,
		fail_brakes: 0,
		fail_buses: 0,
		fail_lamps: 0,
		fail_noise: 0,
		fail_road_wheels: 0,
		fail_seatbelt_installation: 0,
		fail_seatbelt_supplementary: 0,
		fail_spedometer: 0,
		fail_steering: 0,
		fail_suspension: 0,
		fail_tyres: 0,
		fail_veh_id: 0,
		fail_visibility: 0
	}

	if (!staff || !staff.length) {
		return 0;
	}
	const nationalAverage = _.find(staff, { userId: 'national' });
	const staffAverages = _.filter(staff, (member) => (member.userId !== 'site' && member.userId !== 'national'));
	const staffCount = staffAverages.length;
	if (!staffCount) {
		return 0;
	}

	staffAverages.forEach((average) => {
		staffTotals = _.mergeWith(staffTotals, average, (val1, val2) => {
			if (val1 == null) { return null; }
			return val1 + val2
		});
	});

	const variation = _.mergeWith(staffTotals, nationalAverage, (val1, val2) => {
		if (val1 == null) { return null; }
		return Math.abs((val1 / staffCount) - val2);
	});

	const averageVariation = _.reduce(variation, (sum, n) => {
		return sum + n;
	}, 0) / 15;

	return averageVariation;
}

export class TestVariation extends Component {
	render() {
		const { staffAverages, index, selectedIndex, selectTestDetail, splitUserData } = this.props;
		const open = selectedIndex === index

		const variation = calculateAverageVariation(staffAverages.month);

		return (
			<Fragment>
				<TestDetail
					title="Average Variation"
					value={`${variation.toFixed(1)}%`}
					status={getTestVariationStatus(variation)}
					open={open}
					onClick={() => selectTestDetail(index)}
				/>
				{open && <TestVariationDetails splitUserData={splitUserData} tests={staffAverages.all} />}
			</Fragment>
		)
	}
}

const TestVariationDetails = ({ tests, splitUserData }) => {

	let series;

	if (splitUserData) {
		series = getChartDataByUserId(tests, getChartData);
	} else {
		series = [
			{
				name: "Average Variation",
				data: getChartData(tests),
				lineWidth: 8,
				color: "#7AC41A"
			}
		];
	}

	return (
		<section className="centre-expanded-detail">
			<LineChart series={series} valueFormatter={value => `${value.toFixed(1)}%`} min={0} max={100} datetime />
		</section>
	);
}

function getChartData(tests) {
	const data = _.map(tests, (value, key) => {
		const average = calculateAverageVariation(value);
		return { x: parseInt(key), y: average };
	});
	return data;
}