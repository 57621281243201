import React, { Component } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';

export class DonutChart extends Component {
  constructor(props) {
    super(props);
    const { data } = this.props;
    
    this.state = {
      chartOptions: {
        title: { text: '' },
        credits: { enabled: false },
        chart: {
          height: 150,
          width: 150,
          backgroundColor: 'transparent'
        },
        legend: {
          enabled: false
        },
        tooltip: {
          style: {
            fontFamily: '"Montserrat", sans-serif',
            fontSize: '14px',
            color: 'black'
          },
          formatter: function() { 
            return `${this.key}: <b>${this.y}</b>`;
          }
        },
        series: [
          { 
            type: 'pie',
            categories: ['Pass', 'Fail', 'Aborted'],
            data: data,
            dataLabels: {
              enabled: false
            },
            innerSize: 90
          }
        ]
      }
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data !== this.props.data) {
      this.setState({
        chartOptions: {
          series: [
            { data: this.props.data }
          ]
        }
      });
    }
  }

  render() {
    const { chartOptions } = this.state;

    return (
      <div className="donut-chart">
        <svg className="logo" viewBox="0 0 77.06 66.04">
          <path d="M45.31,38.94h31.2l-22.4-38.8l-15.6,27.02L22.91,0.15l-22.4,38.8h31.2h0.04l-15.65,27.1h44.8l-15.65-27.1
              H45.31z M54.12,15.74l8.89,15.4H45.22L54.12,15.74z M14.02,31.15l8.89-15.4l8.89,15.4H14.02z M29.62,58.24l8.89-15.4l8.89,15.4
              H29.62z"/>
        </svg>
        <HighchartsReact
          highcharts={Highcharts}
          options={chartOptions}
        />
        <ul className="pie-legend">
          <li><span></span> Pass</li>
          <li><span></span> Fail</li>
          <li><span></span> Aborted</li>
        </ul>
      </div>
    )
  }
}