import React from 'react';
import moment from 'moment';

import { Select } from '.';

export const MonthSelector = ({ selectedMonth, onChange }) => {
  let d = new moment().subtract(1, "month").startOf('month');
  const dates = [];
  
  while (dates.length < 12) {
    dates.push({
      label: d.format('MMMM YYYY'),
      value: d.valueOf()
    });
    d.subtract(1, 'month');
  }

  return (
    <Select
      name="month"
      value={selectedMonth}
      onChange={onChange}
      options={dates}
      className="dark"
    />
  );
}
