import React from 'react';

import symbols from '../media/svg-symbols.svg';

export const Alert = ({ alert: { message, type } }) => (
  <div className={`alert ${type}`}>
    <svg className="icon-alert"><use xlinkHref={`${symbols}#icon-${type === 'alert--danger' ? 'cross' : 'check'}`}></use></svg>
    <span>{message}</span>
    <div className="alert__timer">
    </div>
  </div>
);