export function parseSeconds(duration) {
  const durationParts = duration.split(':');
  if (durationParts.length === 2) {
    durationParts.unshift(0);
  }
  const hours = parseInt(durationParts[0]) * 60 * 60;
  const minutes = parseInt(durationParts[1]) * 60;
  const seconds = parseInt(durationParts[2]);
  return hours + minutes + seconds;
}

export function secondsToDuration(seconds) {
  var h = Math.floor(seconds / 3600);
  var m = Math.floor(seconds % 3600 / 60);
  var s = Math.floor(seconds % 3600 % 60);
  return h ? `${zeroPad(h)}:${zeroPad(m)}:${zeroPad(s)}` : `${zeroPad(m)}:${zeroPad(s)}`; 
}

export function zeroPad(value) {
  return value < 10 ? `0${value}` : value;
}