import React, { Component } from 'react';

import { validateEmail } from '../helpers/validation';
import { Button } from './';

import symbols from '../media/svg-symbols.svg';

export class NewStaffMember extends Component {
	constructor(props) {
		super(props);

		this.state = {
			name: '',
			email: '',
			pwd: '',
			userId: '',
			role: '',
			submitted: false,
			submitting: false,
			formToggled: false,
			emailValid: true
		}
	}

	toggleForm = (e) => {
		const { name, formToggled } = this.state;
		this.setState({
			name: formToggled ? '' : name,
			formToggled: !formToggled
		});
	}

	handleChange = (e) => {
		const { formToggled, email } = this.state;
		const { name, value } = e.target;
		const toggled = name === 'name' ? !!name : formToggled

		if (!!email && name === 'email') {
			const emailValid = validateEmail(value);
			this.setState({ [name]: value, emailValid, formToggled: toggled });
		} else {
			this.setState({ [name]: value, formToggled: toggled });
		}
	}

	onSubmit = (e) => {
		e.preventDefault();
		const { createStaffMember, centreId } = this.props;
		const { name, email, pwd, userId, role } = this.state;

		this.setState({
			submitted: true
		});

		if (!name || !pwd || !userId || !role || (role === "2" && !email)) {
			return false;
		}

		createStaffMember(centreId, name, email, pwd, userId, role, () => {
			this.setState({
				name: '',
				email: '',
				pwd: '',
				userId: '',
				role: '',
				submitted: false,
				submitting: false,
				formToggled: false,
				emailValid: true
			});
		});
	}

	render() {
		const { name, email, pwd, userId, role, submitted, submitting, formToggled, emailValid } = this.state;
		const iconName = formToggled ? 'icon-minus' : 'icon-plus';

		return (
			<form className={`user user--new ${formToggled ? 'active' : ''}`} onSubmit={this.onSubmit}>
				<label className="user__header">
					<span className="btn btn--secondary" onClick={this.toggleForm}>
						<svg className={iconName}><use xlinkHref={`${symbols}#${iconName}`}></use></svg>
					</span>
					<span className="user__name">
						<input autoComplete="new-password" type="text" name="name" value={name} onChange={this.handleChange} placeholder="New User" />
						{(submitted && !name) && <span className="field-validation-error">Name is required</span>}
					</span>
				</label>
				<div className="form-inner">
					<div className="row two-field">
						<div className="field">
							<input autoComplete="new-password" type="text" name="email" value={email} onChange={this.handleChange} placeholder="Email Address" />
							{(submitted && (role === "2" && !email)) && <span className="field-validation-error">Email Address is required</span>}
							{(submitted && !emailValid) && <span className="field-validation-error">Please enter a valid email address</span>}
						</div>
						<div className="field">
							<input autoComplete="new-password" type="password" name="pwd" value={pwd} onChange={this.handleChange} placeholder="Password" />
							{(submitted && !pwd) && <span className="field-validation-error">Password is required</span>}
						</div>
					</div>
					<div className="row two-field">
						<div className="field">
							<input autoComplete="new-password" type="text" name="userId" value={userId} onChange={this.handleChange} placeholder="User ID" />
							{(submitted && !userId) && <span className="field-validation-error">User ID is required</span>}
						</div>
						<div className="field">
							<select name="role" value={role} onChange={this.handleChange}>
								<option>Select Role</option>
								<option value="2">Centre Manager</option>
								<option value="3">Tester</option>
							</select>
							{(submitted && !role) && <span className="field-validation-error">Role is required</span>}
						</div>
					</div>
					<div className="row">
						<Button type="submit" text="Submit" loading={submitting} />
					</div>
				</div>
			</form>
		);
	}
};