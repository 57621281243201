import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import { getIPVariationStatus } from '../helpers/ragStatuses';
import { getChartDataByUserId } from '../helpers/misc';
import { TestDetail, LineChart } from '.';

function getIPVariation(tests) {
	if (!tests || !tests.length) {
		return 0;
	}
	const ips = _.groupBy(tests, 'client_ip_completion');
	// const largestIp = _.sortBy(ips, value => -value.length)[0];
	// return 100 - (largestIp.length / tests.length) * 100;
	return Object.keys(ips).length;
}

export class TestIP extends Component {
	render() {
		const { tests, index, selectedIndex, selectTestDetail, splitUserData } = this.props;
		const open = selectedIndex === index

		const variation = getIPVariation(tests.month);

		return (
			<Fragment>
				<TestDetail
					title="Unique IPs"
					value={variation}
					status={getIPVariationStatus(variation)}
					open={open}
					onClick={() => selectTestDetail(index)}
				/>
				{open && <TestIPDetails tests={tests.all} splitUserData={splitUserData} />}
			</Fragment>
		)
	}
}

const TestIPDetails = ({ tests, splitUserData }) => {
	let series;
	if (splitUserData) {
		series = getChartDataByUserId(tests, getChartData);
	} else {
		series = [
			{
				name: "Unique IPs",
				data: getChartData(tests),
				lineWidth: 8,
				color: "#7AC41A"
			}
		];
	}

	return (
		<section className="centre-expanded-detail">
			<LineChart title="Unique IPs" series={series} datetime />
		</section>
	);
}

function getChartData(tests) {
	const data = _.map(tests, (value, key) => {
		const average = getIPVariation(value);
		return { x: parseInt(key), y: average };
	});
	return data;
}