import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import { getChartDataByUserId } from "../helpers/misc";
import { TestDetail, LineChart } from '.';

export class TestCount extends Component {
	render() {
		const { tests, index, selectedIndex, selectTestDetail, splitUserData } = this.props;
		const open = selectedIndex === index
		const monthCount = tests.month ? tests.month.length : 0;

		return (
			<Fragment>
				<TestDetail
					title="Number of Tests"
					value={monthCount}
					status="green"
					open={open}
					onClick={() => selectTestDetail(index)}
				/>
				{open && <TestCountDetails splitUserData={splitUserData} tests={tests.all} />}
			</Fragment>
		)
	}
}

const TestCountDetails = ({ tests, splitUserData }) => {

	let series;

	if (splitUserData) {
		series = getChartDataByUserId(tests, getChartData);
	} else {
		series = [
			{
				name: "Number of Tests",
				data: getChartData(tests),
				lineWidth: 8,
				color: "#7AC41A"
			}
		];
	}

	return (
		<section className="centre-expanded-detail">
			<LineChart series={series} datetime />
		</section>
	);
}

function getChartData(tests) {
	return _.map(tests, (value, key) => ({ x: parseInt(key), y: value.length }));
}