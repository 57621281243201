import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import { centreActions } from '../actions';
import { StaffMember, MonthSelector, NewStaffMember, StaffMemberEditor } from '.';

class StaffList extends Component {
    constructor(props) {
        super(props);
        const { centre, setSelectedUsers } = props;

        const staffCount = centre.staff ? centre.staff.length : 0;

        const staff = _.mapKeys(_.map(centre.staff, member => ({
            ...member,
            selected: true
        })), 'id');

        this.state = {
            staff,
            staffCount,
            selectedCount: staffCount,
            editing: null
        };

        setSelectedUsers(staff);
    }

    toggleUsers = () => {
        const { setSelectedUsers } = this.props;
        const { staff, selectedCount, staffCount } = this.state;

        const selectAll = selectedCount !== staffCount;
        const newCount = selectAll ? staffCount : 0;

        _.map(staff, member => { member.selected = selectAll });

        this.setState({
            staff,
            selectedCount: newCount
        });

        setSelectedUsers(_.mapKeys(_.filter(staff, { selected: true }), 'id'));
    }

    selectStaffMember = (id) => {
        const { setSelectedUsers } = this.props;
        const { staff } = this.state;
        staff[id].selected = !staff[id].selected;

        const selectedStaff = _.filter(staff, { selected: true });

        const selectedCount = selectedStaff.length;

        this.setState({
            staff,
            selectedCount
        });

        setSelectedUsers(_.mapKeys(selectedStaff, 'id'));
    }

    deleteStaffMember = (e, id) => {
        e.preventDefault();
        e.stopPropagation();
        if (!window.confirm('Are you sure you want to delete this user? This action cannot be undone.')) {
            return;
        }
        const { deleteStaffMember, setSelectedUsers } = this.props;
        const { staff } = this.state;

        staff[id].loading = true;
        this.setState({
            staff
        });

        deleteStaffMember(id, () => {
            const newStaff = _.omit(staff, id);
            this.setState({
                staff: newStaff
            });
            setSelectedUsers(newStaff);
        });
    }

    createStaffMember = (centreId, name, email, pwd, userId, role, callback) => {
        const { createStaffMember } = this.props;

        createStaffMember(centreId, name, email, pwd, userId, role, () => {
            const { centre, setSelectedUsers } = this.props;
            const { staff, staffCount, selectedCount } = this.state;

            const newMember = centre.staff[centre.staff.length - 1];
            newMember.selected = true;

            staff[newMember.id] = newMember;

            this.setState({
                staff,
                staffCount: staffCount + 1,
                selectedCount: selectedCount + 1
            });

            setSelectedUsers(staff);

            callback();
        });
    }

    editStaffMember = (memberId, data, cb) => {
        const { editStaffMember } = this.props;

        editStaffMember(memberId, data, () => {
            const { staff } = this.state;
            staff[memberId] = Object.assign({}, staff[memberId], data);
            this.setState({ staff, editing: null });
            if (cb) cb();
        })
    }

    render() {
        const { selectedMonth, setMonth, centre, adminUser } = this.props;
        const { staff, selectedCount, staffCount } = this.state;

        const totalSelection = selectedCount === staffCount;
        const partialSelection = !totalSelection && selectedCount > 0;

        return (
            <div className="column">
                <div className="sub-header">
                    <div className="select-all" onClick={this.toggleUsers}>
                        {totalSelection && <svg viewBox="0 0 18.86 13.68"><path fill="none" stroke="#fff" strokeWidth="3" d="M17.63,1.2L7.56,11.34L1.25,4.93" /></svg>}
                        {partialSelection && <span className="select-all__indicator"></span>}
                    </div>
                    <MonthSelector selectedMonth={selectedMonth} onChange={setMonth} />
                    <h2>Users</h2>
                </div>
                <div className="centre-users">
                    {Object.values(staff)
                        .sort((a, b) => {
                            let aDisabled = Boolean(a.disabled);
                            let bDisabled = Boolean(b.disabled);

                            if (aDisabled === bDisabled) {
                                return 0;
                            } else if (aDisabled && !bDisabled) {
                                return 1;
                            } else {
                                return -1;
                            }
                        })
                        .map(member => (
                            this.state.editing === member.id
                                ?
                                <StaffMemberEditor
                                    key={member.id}
                                    member={member}
                                    editStaffMember={this.editStaffMember}
                                    closeEditor={() => {
                                        this.setState({ editing: null })
                                    }}
                                />
                                :
                                <StaffMember
                                    adminUser={adminUser}
                                    key={member.id}
                                    member={member}
                                    selectStaffMember={this.selectStaffMember}
                                    deleteStaffMember={this.deleteStaffMember}
                                    editing={(e, id) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        this.setState({ editing: id })
                                    }}
                                />
                        ))
                    }
                    {adminUser && <NewStaffMember centreId={centre.id} createStaffMember={this.createStaffMember} />}
                </div>
            </div>
        );
    }
}

function mapStateToProps({ centre }) {
    return { centre };
}

export default connect(mapStateToProps, {
    deleteStaffMember: centreActions.deleteStaffMember,
    createStaffMember: centreActions.createStaffMember,
    editStaffMember: centreActions.editStaffMember
})(StaffList);