import React from 'react';

import symbols from '../media/svg-symbols.svg';

export const TestDetail = ({ title, value, status, open, onClick }) => {
  return (
    <div className={`centre-detail centre-detail--${status} ${open ? 'centre-detail--open' : ''}`} onClick={onClick}>
      <strong className="centre-detail__heading">{title}</strong>
      <span className="centre-detail__value">{value}</span>
      <span className="btn">{open ? 'Less Info' : 'More Info'}</span>
      {open && <span className="centre-detail__arrow"><svg className="icon-chevron-down"><use xlinkHref={`${symbols}#icon-chevron-down`}></use></svg></span>}
    </div>
  )
};
