import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';

export class Navigation extends Component {
  toggleNav = (e) => {
    e.preventDefault();
  }

  logout = (e) => {
    const { logout } = this.props;
    e.preventDefault();
    logout();
  }

  render() {
	const { user } = this.props;

    return (
      <header id="header-main">
        <div className="container">
          <Link to="/centres" className="logo">
            <svg viewBox="0 0 77.06 66.04">
              <path d="M45.31,38.94h31.2l-22.4-38.8l-15.6,27.02L22.91,0.15l-22.4,38.8h31.2h0.04l-15.65,27.1h44.8l-15.65-27.1
                H45.31z M54.12,15.74l8.89,15.4H45.22L54.12,15.74z M14.02,31.15l8.89-15.4l8.89,15.4H14.02z M29.62,58.24l8.89-15.4l8.89,15.4
                H29.62z" />
            </svg>
          </Link>
          <nav id="main-nav">
            {user.data && (
              <Fragment>
                <ul className="sliding-nav">
                  <li><Link to="/">Centres</Link></li>
                  <li><Link to="/login" onClick={this.logout}>Log Out</Link></li>
                </ul>
              </Fragment>
            )}
          </nav>
        </div>
      </header>
    );
  }
}
