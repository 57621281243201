import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';

import { centreActions } from '../actions';
import { isAdmin } from '../helpers/user';

import {
    Button, CentreHeader, StaffList, Loading, TestDuration, TestCount, TestLogins, RawData,
    TestIP, TestOutcomes, TestVariation, MonthSelector, Error, FileUpload, NewReport
} from '../components';

class Centre extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedIndex: null,
            selectedMonth: new moment().startOf('month').subtract(1, "month").valueOf(),
            selectedUsers: [],
            showDataOverlay: false,
            tests: {},
            staffTests: {},
            report: null,
            editReport: false
        };
    }

    componentDidUpdate(pp) {
        if (pp.centre !== this.props.centre)
            this.filterData();
    }

    componentDidMount() {
        const { getCentre, match } = this.props;
        const { selectedMonth } = this.state;
        const { id } = match.params;
        getCentre(id, selectedMonth, () => {
            this.filterData();
        });
    }

    selectTestDetail = (index) => {
        const { selectedIndex } = this.state;
        const newIndex = selectedIndex === index ? null : index;
        this.setState({
            selectedIndex: newIndex
        });
    }

    setSelectedUsers = (users) => {
        this.setState({
            selectedUsers: users
        }, this.filterData);
    }

    uploadTestData = (testData, reset) => {
        const { centre, uploadTestData } = this.props;

        this.read(testData).then(data => {
            uploadTestData(centre.id, data, () => {
                reset('');
            });
        }).catch(err => {
            reset(err);
        });
    }

    uploadStaffTestData = (testData, reset) => {
        const { centre, uploadStaffTestData } = this.props;
        const { selectedMonth } = this.state;

        this.read(testData).then(data => {
            uploadStaffTestData(centre.id, selectedMonth, data, () => {
                reset('');
            });
        }).catch(err => {
            reset(err);
        });
    }

    read(file) {
        return new Promise((resolve, reject) => {
            let ext = /(\.[^.]+)$/.exec(file.name);
            if (!ext || (ext[1].toLowerCase() !== ".csv")) {
                return reject("Not a CSV file.");
            }
            let fr = new FileReader();
            fr.onload = readEvent => {
                resolve(readEvent.target.result);
            }
            fr.readAsText(file);
        });
    }

    filterData = () => {
        this.filterTests();
        this.filterStaffAverages();
        this.filterReports();
    }

    filterTests = () => {
        const { centre } = this.props;

        const { selectedUsers, selectedMonth } = this.state;
        const tests = centre.tests;

        const month = selectedMonth ? moment(selectedMonth) : moment();

        // Filter tests by selected users.
        const filteredTests = _.filter(tests, tests => !!_.find(selectedUsers, { userId: tests.userId, disabled: 0 }));
        // Group tests by month
        const groupedTests = _.groupBy(filteredTests, test => moment(test.test_date).startOf('month').valueOf());
        // Get current month's tests
        const monthTests = groupedTests[month.startOf('month').valueOf()];

        this.setState({
            tests: {
                all: groupedTests,
                month: monthTests
            }
        });
    }

    filterStaffAverages = () => {
        const { centre } = this.props;
        const { selectedUsers, selectedMonth } = this.state;
        const staff = centre.staffAverages;

        const month = selectedMonth ? moment(selectedMonth) : moment();

        // Filter tests by selected users
        const filteredStaff = _.filter(staff, member => {
            return member.userId === 'site' || member.userId === 'national' || !!_.find(selectedUsers, { userId: member.userId, disabled: 0 })
        });
        // Group tests by month
        const groupedStaff = _.groupBy(filteredStaff, staff => moment(staff.date).startOf('month').valueOf());
        // Get current month's tests
        const monthStaff = groupedStaff[month.startOf('month').valueOf()];

        this.setState({
            staffTests: {
                all: groupedStaff,
                month: monthStaff
            }
        });
    }

    filterReports = () => {
        const { centre } = this.props;
        const { selectedMonth } = this.state;

        const month = selectedMonth ? moment(selectedMonth) : moment();

        const report = _.find(centre.reports, report => {
            return moment(report.date).format('YYYY-MM-DD') === month.format('YYYY-MM-DD');
        });

        this.setState({
            report,
            editReport: false
        });
    }

    setMonth = (e) => {
        this.setState({
            selectedMonth: parseInt(e.target.value)
        }, this.filterData);
    }

    createReport = (comment) => {
        const { createReport, centre } = this.props;
        const { selectedMonth } = this.state;

        createReport(centre.id, comment, selectedMonth, () => {
            this.filterReports();
        });
    }

    updateReport = (reportId, comment) => {
        const { updateReport } = this.props;

        updateReport(reportId, comment, () => {
            this.filterReports();
        });
    }

    toggleEditReport = (e) => {
        e.preventDefault();
        this.setState({
            editReport: !this.state.editReport
        });
    }

    sendReport = reportId => {
        if (window.confirm("Are you sure you want to send this report to all of the centre managers for this centre?")) {
            this.props.sendReport(reportId);
        }
    }

    toggleDataOverlay = (e) => {
        e.preventDefault();
        const { showDataOverlay } = this.state;
        this.setState({
            showDataOverlay: !showDataOverlay
        });
    }

    render() {
        const { centre, user, match, settings } = this.props;
        const { reportSending } = centre;
        const { selectedIndex, selectedMonth, selectedUsers, showDataOverlay, tests, staffTests, report, editReport } = this.state;
        const { id } = match.params;

        const adminUser = isAdmin(user);

        if (centre.loading) {
            return <Loading pad />
        }

        if (centre.error) {
            return <Error error={centre.error} />
        }

        return (
            <div className="container">
                <div className="column">
                    <div className="header">
                        <MonthSelector selectedMonth={selectedMonth} onChange={this.setMonth} />
                        <CentreHeader centre={centre} id={id} />
                    </div>
                </div>
                <div className="column centre-details">
                    <TestDuration index={0} splitUserData={settings.splitUserData} tests={tests} selectedIndex={selectedIndex} selectTestDetail={this.selectTestDetail} />
                    <TestLogins index={1} splitUserData={settings.splitUserData} tests={tests} selectedIndex={selectedIndex} selectTestDetail={this.selectTestDetail} />
                    <TestIP index={2} splitUserData={settings.splitUserData} tests={tests} selectedIndex={selectedIndex} selectTestDetail={this.selectTestDetail} />
                    <TestOutcomes index={3} splitUserData={settings.splitUserData} tests={tests} staffAverages={staffTests} selectedUsers={selectedUsers} selectedIndex={selectedIndex} selectTestDetail={this.selectTestDetail} />
                    <TestCount index={4} splitUserData={settings.splitUserData} tests={tests} selectedIndex={selectedIndex} selectTestDetail={this.selectTestDetail} />
                    <TestVariation index={5} splitUserData={settings.splitUserData} staffAverages={staffTests} selectedIndex={selectedIndex} selectTestDetail={this.selectTestDetail} />
                </div>
                <StaffList 
                    setSelectedUsers={this.setSelectedUsers} 
                    selectedMonth={selectedMonth} 
                    setMonth={this.setMonth} 
                    adminUser={adminUser} 
                />
                {(adminUser || report) && (
                    <div className="column">
                        <div className="report-buttons">
                            {(adminUser && report) && <>
                                <Button onClick={this.toggleEditReport} text="Edit" />
                                <Button onClick={() => this.sendReport(report.id)} text="Send report" loading={reportSending} />
                            </>}
                            <Button onClick={this.toggleDataOverlay} text="View Test Logs" />
                        </div>
                        <h2>Report</h2>
                        {(report && !editReport) ? <div className="report" dangerouslySetInnerHTML={{ __html: report.comment }} /> : (
                            <NewReport
                                createReport={this.createReport}
                                updateReport={this.updateReport}
                                report={report}
                            />
                        )}
                        <RawData
                            title="Test Logs"
                            tests={tests}
                            selectedMonth={selectedMonth}
                            open={showDataOverlay}
                            handleClose={this.toggleDataOverlay}
                            setMonth={this.setMonth}
                        />
                    </div>
                )}
                {adminUser && (
                    <Fragment>
                        <div className="column-m-2-1">
                            <h2>Upload Test Data</h2>
                            <FileUpload onSubmit={this.uploadTestData} allowedTypes={['.csv']} />
                        </div>
                        <div className="column-m-2-1">
                            <h2>Upload Staff TQI</h2>
                            <FileUpload onSubmit={this.uploadStaffTestData} allowedTypes={['.csv']} />
                        </div>
                    </Fragment>
                )}
                {adminUser && (
                    <div className="column">
                        <h2>Other settings</h2>
                        {centre.disabled
                            ?
                            <Button loading={centre.editing} primary={true} full={true} text="Enable Center" onClick={() => {
                                if (window.confirm("Are you sure you want to enable this test center?")) {
                                    this.props.updateCentre(centre.id, { disabled: false })
                                }
                            }} />
                            :
                            <Button loading={centre.editing} destructive={true} full={true} text="Disable Center" onClick={() => {
                                if (window.confirm("Are you sure you want to disable this test center?")) {
                                    this.props.updateCentre(centre.id, { disabled: true })
                                }
                            }} />
                        }
                    </div>
                )}
            </div>
        );
    }
}

function mapStateToProps({ centre, user, settings }) {
    return {
        centre,
        user,
        settings
    };
}

export default connect(mapStateToProps, {
    getCentre: centreActions.getCentre,
    updateCentre: centreActions.updateCentre,
    uploadTestData: centreActions.uploadTestData,
    uploadStaffTestData: centreActions.uploadStaffTestData,
    createReport: centreActions.createReport,
    updateReport: centreActions.updateReport,
    sendReport: centreActions.sendReport
})(Centre);