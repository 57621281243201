import axios from 'axios';
import { apiUrl } from '../constants';
import { history } from '../helpers/history';
import { alertActions } from '.';

import { deleteCookie } from '../helpers/cookie';

export const userConstants = {
	LOGIN_REQUEST: 'USER_LOGIN_REQUEST',
	LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',
	LOGIN_FAILURE: 'USER_LOGIN_FAILURE',

	LOGOUT_REQUEST: 'USER_LOGOUT_REQUEST',
	LOGOUT_SUCCESS: 'USER_LOGOUT_SUCCESS',
	LOGOUT_FAILURE: 'USER_LOGOUT_FAILURE',

    LOGIN_EXISTING_SUCCESS: 'USER_LOGIN_EXISTING_SUCCESS'
};

function login(email, pwd) {
	return (dispatch) => {
		dispatch(request());

		return axios.post(`${apiUrl}/security/login`, {
			email,
			pwd
		}, {
			withCredentials: true
		}).then(
			user => {
				dispatch(success(user));
				history.push('/');
			},
			error => {
				dispatch(failure(error.response.data.msg));
				dispatch(alertActions.error(error.response.data.msg));
			}
		);
	};

	function request() { return { type: userConstants.LOGIN_REQUEST }; }
	function success(user) { return { type: userConstants.LOGIN_SUCCESS, user }; }
	function failure(error) { return { type: userConstants.LOGIN_FAILURE, error }; }
}

function getMe() {
	return (dispatch) => {

		return axios.get(`${apiUrl}/security/me`, {
			withCredentials: true,
		}).then(
			user => {
				dispatch(success(user));
				history.push('/');
			},
			error => { 
                dispatch(alertActions.error(error.response.data.msg));
            }
		);
	};

	function success(user) { return { type: userConstants.LOGIN_EXISTING_SUCCESS, user }; }
}

function logout() {
	return (dispatch) => {
		dispatch(request());

		return axios.post(`${apiUrl}/security/logout`, {}, {
			withCredentials: true,
		}).then(
			user => {
				deleteCookie('authorization');
				dispatch(success(user))
				history.push('/login');
			},
			error => {
				dispatch(failure(error.response.data.msg))
				dispatch(alertActions.error(error.response.data.msg));
			}
		);
	};

	function request() { return { type: userConstants.LOGOUT_REQUEST }; }
	function success(user) { return { type: userConstants.LOGOUT_SUCCESS, user }; }
	function failure(error) { return { type: userConstants.LOGOUT_FAILURE, error }; }
}

export const userActions = {
	login,
	logout,
	getMe
};
