import React from 'react';
import { connect } from 'react-redux';
import { Router, Route, Switch } from "react-router-dom";

import { alertActions, userActions } from './actions';
import { history } from './helpers/history';

import { Centre, Centres, Login } from './containers';
import { Alert, Navigation, PrivateRoute } from './components';

class App extends React.Component {
	constructor(props) {
		super(props);

		history.listen(() => {
			const { clearAlertsAction } = this.props;
			clearAlertsAction();
		});
	}

	componentDidMount() {
		this.props.getMe();
	}

	render() {
		const { alerts, logout, user } = this.props;

		return (
			<Router history={history}>
				{alerts.message && <Alert alert={alerts} />}
				<Navigation logout={logout} user={user} />
				<div id="wrapper">
					<div id="wrapper-inner">
						<div id="main">
							<Switch>
								<PrivateRoute path="/centre/:id" component={Centre} user={user} />
								<Route path="/login" component={Login} />
								<PrivateRoute path="/" component={Centres} user={user} />
							</Switch>
						</div>
					</div>
				</div>
			</Router>
		);
	}
}

function mapStateToProps({ alerts, user }) {
	return { alerts, user };
}

export default connect(mapStateToProps, {
	clearAlertsAction: alertActions.clear,
	logout: userActions.logout,
	getMe: userActions.getMe
})(App);
