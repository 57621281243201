import React from 'react';

import { roles } from '../constants';

import { Checkbox, Loading } from '.';

import symbols from '../media/svg-symbols.svg';

export const StaffMember = ({ member: { id, fName, lName, role, selected, loading, disabled }, selectStaffMember, deleteStaffMember, editing, adminUser }) => (
    <label className={`user user--${disabled ? 'disabled' : 'enabled'}`}>
        <Checkbox value={disabled ? false : selected} onChange={() => {
            if (!disabled) selectStaffMember(id)
        }} />
        <span className="user__name">
            <svg className="icon-user"><use xlinkHref={`${symbols}#icon-user`}></use></svg> {`${fName} ${lName}`}
        </span>
        <span className="user__position">{roles[role]}</span>
        {adminUser && <span className="user__edit" onClick={e => editing(e, id)}>
            <svg className="icon-edit"><use xlinkHref={`${symbols}#icon-edit`}></use></svg>
        </span>}
        {adminUser && (loading ? <Loading /> : <span className="user__delete" onClick={(e) => deleteStaffMember(e, id)}><svg className="icon-bin"><use xlinkHref={`${symbols}#icon-bin`}></use></svg></span>)}
    </label>
);