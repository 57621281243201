import React, { Component, Fragment } from 'react';
import _ from 'lodash';

import { getDurationStatus } from '../helpers/ragStatuses';
import { parseSeconds, secondsToDuration } from '../helpers/formatters';

import { TestDetail, LineChart } from '.';

import { getChartDataByUserId } from "../helpers/misc";

function getAverageDuration(tests) {
    if (!tests || !tests.length) {
        return 0;
    }
    let dataSet = tests.filter(test => {
        return test.test_type !== "Re-Test"
    });
    if (!dataSet.length) {
        return 0;
    }
    const totalSeconds = _.reduce(dataSet, (sum, test) => {
        return sum + parseSeconds(test.test_duration);
    }, 0);

    return Math.floor(totalSeconds / dataSet.length);
}

export class TestDuration extends Component {
    render() {
        const { tests, index, selectedIndex, selectTestDetail, splitUserData } = this.props;
        const open = selectedIndex === index;
        const averageDuration = secondsToDuration(getAverageDuration(tests.month));
        return (
            <Fragment>
                <TestDetail
                    title="Average Test Duration"
                    value={averageDuration}
                    status={getDurationStatus(averageDuration)}
                    open={open}
                    onClick={() => selectTestDetail(index)}
                />
                {open && <TestDurationDetails splitUserData={splitUserData} tests={tests.all} />}
            </Fragment>
        )
    }
}

const TestDurationDetails = ({ tests, splitUserData }) => {

    let series;

    if (splitUserData) {
        series = getChartDataByUserId(tests, getChartData);
    } else {
        series = [
            {
                name: "Average Test Duration",
                data: getChartData(tests),
                lineWidth: 8,
                color: "#7AC41A"
            }
        ];
    }

    return (
        <section className="centre-expanded-detail">
            <LineChart
                series={series}
                valueFormatter={secondsToDuration}
                datetime
            />
        </section>
    );
}

function getChartData(tests) {
    const data = _.map(tests, (value, key) => {
        const average = getAverageDuration(value);
        return { x: parseInt(key), y: average };
    });
    return data;
}