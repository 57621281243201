import _ from 'lodash';
import { centreConstants } from '../actions';

export default function (state = { loading: true }, action) {
  switch (action.type) {
    case centreConstants.GET_REQUEST:
      return { loading: true };
    case centreConstants.GET_SUCCESS:
      return _.mapKeys(action.centres.data.results, 'id');
    case centreConstants.GET_FAILURE:
      return { error: action.error };

    case centreConstants.CREATE_SUCCESS:
        const centre = action.centre.data.item;
        return { ...state, [centre.id]: centre };

    case centreConstants.UPDATE_ONE_SUCCESS:
        return { ...state, [action.centreId]: { ...state[action.centreId], ...action.changes }  };

    default:
      return state;
  }
}
