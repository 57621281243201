export const apiUrl = process.env.REACT_APP_API_ROOT;

export const roles = {
	0: 'Superuser',
	1: 'Admin',
	2: 'Centre Manager',
	3: 'Tester'
};

export const testStatuses = {
	duration: {
        acceptable: 2520, // 42 minutes
        greenTolerance: 300, // 5 minutes
		amberTolerance: 720 // 12 minutes
	},
	logins: {
		acceptable: 10, // 10% non 2FA logins
		tolerance: 10 // 10% tolerance
	},
	ipVariation: {
		acceptable: 1, // 1 unique IP - green
		tolerance: 1 // 2 unique IPs - amber. Any more -red
	},
	variation: {
		acceptable: 10, // 10% variation
		tolerance: 10 // 10% tolerance
	},
	outcomes: {
		acceptable: 20, // 20% failed or aborted
		tolerance: 10 // 10% tolerance
	}
};
