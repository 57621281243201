import axios from 'axios';
import { apiUrl } from '../constants';
import { alertActions } from '.';
import moment from 'moment';

export const centreConstants = {
	GET_REQUEST: 'CENTRES_GET_REQUEST',
	GET_SUCCESS: 'CENTRES_GET_SUCCESS',
	GET_FAILURE: 'CENTRES_GET_FAILURE',

	GET_ONE_REQUEST: 'CENTRE_GET_REQUEST',
	GET_ONE_SUCCESS: 'CENTRE_GET_SUCCESS',
	GET_ONE_FAILURE: 'CENTRE_GET_FAILURE',

	CREATE_REQUEST: 'CENTRE_CREATE_REQUEST',
	CREATE_SUCCESS: 'CENTRE_CREATE_SUCCESS',
    CREATE_FAILURE: 'CENTRE_CREATE_FAILURE',
    
    UPDATE_ONE_REQUEST: 'CENTRE_UPDATE_ONE_REQUEST',
	UPDATE_ONE_SUCCESS: 'CENTRE_UPDATE_ONE_SUCCESS',
	UPDATE_ONE_FAILURE: 'CENTRE_UPDATE_ONE_FAILURE',

	CREATE_STAFF_REQUEST: 'CENTRE_CREATE_STAFF_REQUEST',
	CREATE_STAFF_SUCCESS: 'CENTRE_CREATE_STAFF_SUCCESS',
	CREATE_STAFF_FAILURE: 'CENTRE_CREATE_STAFF_FAILURE',

	DELETE_STAFF_REQUEST: 'CENTRE_DELETE_STAFF_REQUEST',
	DELETE_STAFF_SUCCESS: 'CENTRE_DELETE_STAFF_SUCCESS',
    DELETE_STAFF_FAILURE: 'CENTRE_DELETE_STAFF_FAILURE',
    
    EDIT_STAFF_REQUEST: "CENTRE_EDIT_STAFF_REQUEST",
    EDIT_STAFF_SUCCESS: "CENTRE_EDIT_STAFF_REQUEST",
    EDIT_STAFF_FAILURE: "CENTRE_EDIT_STAFF_REQUEST",

	UPLOAD_TEST_DATA_REQUEST: 'CENTRE_UPLOAD_TEST_DATA_REQUEST',
	UPLOAD_TEST_DATA_SUCCESS: 'CENTRE_UPLOAD_TEST_DATA_SUCCESS',
	UPLOAD_TEST_DATA_FAILURE: 'CENTRE_UPLOAD_TEST_DATA_FAILURE',

	UPLOAD_STAFF_TEST_DATA_REQUEST: 'CENTRE_UPLOAD_STAFF_TEST_DATA_REQUEST',
	UPLOAD_STAFF_TEST_DATA_SUCCESS: 'CENTRE_UPLOAD_STAFF_TEST_DATA_SUCCESS',
	UPLOAD_STAFF_TEST_DATA_FAILURE: 'CENTRE_UPLOAD_STAFF_TEST_DATA_FAILURE',

	CREATE_REPORT_REQUEST: 'CENTRE_CREATE_REPORT_REQUEST',
	CREATE_REPORT_SUCCESS: 'CENTRE_CREATE_REPORT_SUCCESS',
	CREATE_REPORT_FAILURE: 'CENTRE_CREATE_REPORT_FAILURE',

	UPDATE_REPORT_REQUEST: 'CENTRE_UPDATE_REPORT_REQUEST',
	UPDATE_REPORT_SUCCESS: 'CENTRE_UPDATE_REPORT_SUCCESS',
	UPDATE_REPORT_FAILURE: 'CENTRE_UPDATE_REPORT_FAILURE',

	SEND_REPORT_REQUEST: 'CENTRE_SEND_REPORT_REQUEST',
	SEND_REPORT_SUCCESS: 'CENTRE_SEND_REPORT_SUCCESS',
    SEND_REPORT_FAILURE: 'CENTRE_SEND_REPORT_FAILURE'

};

function getCentres() {
	return (dispatch) => {
		dispatch(request());

		return axios.get(`${apiUrl}/centres`, {
			withCredentials: true
		})
			.then(
				centres => dispatch(success(centres)),
				error => {
					dispatch(failure(error.response.data.msg));
					dispatch(alertActions.error(error.response.data.msg));
				}
			);
	};

	function request() { return { type: centreConstants.GET_REQUEST }; }
	function success(centres) { return { type: centreConstants.GET_SUCCESS, centres }; }
	function failure(error) { return { type: centreConstants.GET_FAILURE, error }; }
}

function getCentre(id, month, callback) {
	const monthParam = moment().startOf('month');
	return (dispatch) => {
		dispatch(request());

		return axios.get(`${apiUrl}/centres/${id}?date=${monthParam.format('YYYY-MM-DD')}`, {
			withCredentials: true
		}).then(
			centre => {
				dispatch(success(centre));
				if (callback) {
					callback();
				}
			},
			error => {
				dispatch(failure(error.response.data.msg));
				dispatch(alertActions.error(error.response.data.msg));
			}
		);
	};

	function request() { return { type: centreConstants.GET_ONE_REQUEST }; }
	function success(centre) { return { type: centreConstants.GET_ONE_SUCCESS, centre }; }
	function failure(error) { return { type: centreConstants.GET_ONE_FAILURE, error }; }
}

function createCentre(name, address, callback) {
	return (dispatch) => {
		dispatch(request());

		return axios.post(`${apiUrl}/centres`, { name, address }, {
			withCredentials: true
		}).then(
			centre => {
				if (callback) {
					callback();
				}
				dispatch(success(centre));
				dispatch(alertActions.success('Centre created successfully'));
			},
			error => {
				dispatch(failure(error.response.data.msg))
				dispatch(alertActions.error(error.response.data.msg));
			}
		);
	};

	function request() { return { type: centreConstants.CREATE_REQUEST }; }
	function success(centre) { return { type: centreConstants.CREATE_SUCCESS, centre }; }
	function failure(error) { return { type: centreConstants.CREATE_FAILURE, error }; }
}

function updateCentre(centreId, changes, callback) {
    return (dispatch) => {
        dispatch(request());

        return axios.put(`${apiUrl}/centres/${centreId}`, changes, {
            withCredentials: true
        }).then(
            res => {
                dispatch(success());
                dispatch(alertActions.success('Centre updated successfully'));
                if (callback) callback();
            },
            error => {
				dispatch(failure(error.response.data.msg));
				dispatch(alertActions.error(error.response.data.msg));
            } 
        );
    };

    function request() { return { type: centreConstants.UPDATE_ONE_REQUEST }; };
	function success() { return { type: centreConstants.UPDATE_ONE_SUCCESS, centreId, changes }; };
	function failure(error) { return { type: centreConstants.UPDATE_ONE_FAILURE, error }; }

}

function createStaffMember(centreId, name, email, pwd, userId, role, callback) {
	const nameParts = name.split(' ');
	const fName = nameParts[0];
	const lName = nameParts.slice(1).join(' ');

	return (dispatch) => {
        dispatch(request());
        
        let payload = {
            fName, lName, email, pwd, userId, role: parseInt(role)
        }

        if (!email) delete payload.email;

		return axios.post(`${apiUrl}/centres/${centreId}/staff`, payload, {
			withCredentials: true
		}).then(
			member => {
				dispatch(success(member));
				dispatch(alertActions.success('Staff member created successfully'));
				if (callback) {
					callback();
				}
			},
			error => {
				dispatch(failure(error.response.data.msg))
				dispatch(alertActions.error(error.response.data.msg));
			}
		);
	};

	function request() { return { type: centreConstants.CREATE_STAFF_REQUEST }; }
	function success(member) { return { type: centreConstants.CREATE_STAFF_SUCCESS, member }; }
	function failure(error) { return { type: centreConstants.CREATE_STAFF_FAILURE, error }; }
};

function deleteStaffMember(id, callback) {
	return (dispatch) => {
		dispatch(request());

		return axios.delete(`${apiUrl}/users/${id}`, {
			withCredentials: true
		}).then(
			member => {
				if (callback) {
					callback();
				}
				dispatch(success(member));
				dispatch(alertActions.success('Staff member deleted successfully'));
			},
			error => {
				dispatch(failure(error.response.data.msg))
				dispatch(alertActions.error(error.response.data.msg));
			}
		);
	};

	function request() { return { type: centreConstants.DELETE_STAFF_REQUEST }; }
	function success() { return { type: centreConstants.DELETE_STAFF_SUCCESS, id }; }
	function failure(error) { return { type: centreConstants.DELETE_STAFF_FAILURE, error }; }
}

function editStaffMember(id, data, callback) {
    return (dispatch) => {
        dispatch(request())

        return axios.put(`${apiUrl}/users/${id}`, data, {
            withCredentials: true
        }).then(
            response => {
                if (callback) callback();
                dispatch(success(response));
                dispatch(alertActions.success('Staff member updated successfully'))
            },
            error => {
                if (callback) callback();
                dispatch(failure(error.response.data.msg));
                dispatch(alertActions.error(error.response.data.msg));
            }
        );    
    };

	function request() { return { type: centreConstants.EDIT_STAFF_REQUEST }; }
	function success() { return { type: centreConstants.EDIT_STAFF_SUCCESS, id, data }; }
	function failure(error) { return { type: centreConstants.EDIT_STAFF_FAILURE, error }; }

}

function uploadTestData(centreId, data, callback) {
	return (dispatch) => {
		dispatch(request());

		return axios.post(`${apiUrl}/centres/${centreId}/tests`, data, {
			headers: {
				'Content-Type': 'text/csv'
			},
			withCredentials: true
		}).then(
			test => {
				if (callback) {
					callback();
				}
				dispatch(success(test));
				dispatch(alertActions.success('Data uploaded successfully'));
				dispatch(getCentre(centreId));
			},
			error => {
				dispatch(failure(error.response.data.msg))
				dispatch(alertActions.error(error.response.data.msg));
			}
		);
	};

	function request() { return { type: centreConstants.UPLOAD_TEST_DATA_REQUEST }; }
	function success(test) { return { type: centreConstants.UPLOAD_TEST_DATA_SUCCESS, test }; }
	function failure(error) { return { type: centreConstants.UPLOAD_TEST_DATA_FAILURE, error }; }
}

function uploadStaffTestData(centreId, month, data, callback) {
	return (dispatch) => {
		dispatch(request());

		return axios.post(`${apiUrl}/centres/${centreId}/staff/tests?date=${moment(month).format('YYYY-MM-DD')}`, data, {
			headers: {
				'Content-Type': 'text/csv'
			},
			withCredentials: true
		}).then(
			test => {
				if (callback) {
					callback();
				}
				dispatch(success(test));
				dispatch(alertActions.success('Data uploaded successfully'));
				dispatch(getCentre(centreId));
			},
			error => {
				dispatch(failure(error.response.data.msg))
				dispatch(alertActions.error(error.response.data.msg));
			}
		);
	};

	function request() { return { type: centreConstants.UPLOAD_STAFF_TEST_DATA_REQUEST }; }
	function success(test) { return { type: centreConstants.UPLOAD_STAFF_TEST_DATA_SUCCESS, test }; }
	function failure(error) { return { type: centreConstants.UPLOAD_STAFF_TEST_DATA_FAILURE, error }; }
}

function createReport(centre, comment, date, callback) {
	return (dispatch) => {
		dispatch(request());
		return axios.post(`${apiUrl}/reports`, {
			centre,
			date: moment(date).format('YYYY-MM-DD'),
			comment
		}, {
			withCredentials: true
		}).then(
			report => {
				dispatch(success(report));
				if (callback) {
					callback();
				}
				dispatch(alertActions.success('Report created successfully'));
			},
			error => {
				dispatch(failure(error.response.data.msg))
				dispatch(alertActions.error(error.response.data.msg));
			}
		);
	};

	function request() { return { type: centreConstants.CREATE_REPORT_REQUEST }; }
	function success(report) { return { type: centreConstants.CREATE_REPORT_SUCCESS, report }; }
	function failure(error) { return { type: centreConstants.CREATE_REPORT_FAILURE, error }; }
}

function updateReport(id, comment, callback) {
	return (dispatch) => {
		dispatch(request());
		return axios.put(`${apiUrl}/reports/${id}`, { comment }, {
			withCredentials: true
		}).then(
			report => {
				dispatch(success({ id, comment }));
				if (callback) {
					callback();
				}
				dispatch(alertActions.success('Report updated successfully'));
			},
			error => {
				dispatch(failure(error.response.data.msg))
				dispatch(alertActions.error(error.response.data.msg));
			}
		);
	};

	function request() { return { type: centreConstants.UPDATE_REPORT_REQUEST }; }
	function success(report) { return { type: centreConstants.UPDATE_REPORT_SUCCESS, report }; }
	function failure(error) { return { type: centreConstants.UPDATE_REPORT_FAILURE, error }; }
}

function sendReport(id, callback) {
	return dispatch => {
		dispatch(request());
		return axios.get(`${apiUrl}/reports/${id}/send`, {
			withCredentials: true
		}).then(
			() => {
				dispatch(success({ id }));
				if (callback) {
					callback();
				}
				dispatch(alertActions.success('Report sent successfully'));
			},
			error => {
				dispatch(failure(error.response.data.msg))
				dispatch(alertActions.error(error.response.data.msg));
			}
		);
	}

	function request() { return { type: centreConstants.SEND_REPORT_REQUEST }; }
	function success(report) { return { type: centreConstants.SEND_REPORT_SUCCESS, report }; }
	function failure(error) { return { type: centreConstants.SEND_REPORT_FAILURE, error }; }
}

export const centreActions = {
	getCentres,
	getCentre,
    createCentre,
    updateCentre,
	createStaffMember,
    deleteStaffMember,
    editStaffMember,
	uploadTestData,
	uploadStaffTestData,
	createReport,
	updateReport,
	sendReport
};
