import React, { PureComponent } from 'react';

export class Checkbox extends PureComponent {
  render() {
    const { name, value, onChange } = this.props;

    return (
      <label className="checkbox">
        <input type="checkbox" name={name} value={value} checked={value} onChange={onChange} />
        <span className="cb">
          <svg viewBox="0 0 18.86 13.68">
            <path fill="none" stroke="#fff" strokeWidth="3" d="M17.63,1.2L7.56,11.34L1.25,4.93" />
          </svg>
        </span>
      </label>
    );
  }
}
