import { centreConstants } from '../actions';
import _ from 'lodash';

const defaultState = {
    loading: true,
    editing: false,
	reportSending: false
}

export default function (state = defaultState, action) {
	switch (action.type) {
		case centreConstants.GET_ONE_REQUEST:
			return { ...state, loading: true };
		case centreConstants.GET_ONE_SUCCESS:
			return {
				...state,
				...action.centre.data.centre,
				loading: false
			};
		case centreConstants.GET_ONE_FAILURE:
			return {
				...state,
				error: action.error,
				loading: false
			};

        case centreConstants.UPDATE_ONE_REQUEST:
            return {
                ...state,
                editing: true
            };

        case centreConstants.UPDATE_ONE_SUCCESS:
            return {
                ...state,
                editing: false,
                ...action.changes
            };

        case centreConstants.UPDATE_ONE_FAILURE:
            return {
                ...state,
                editing: false,
                error: action.error
            };

		case centreConstants.CREATE_STAFF_SUCCESS:
			state.staff.push(action.member.data.user);
			return { ...state, };

		case centreConstants.DELETE_STAFF_SUCCESS:
			state.staff.splice(_.findIndex(state.staff, { id: action.id }), 1);
            return { ...state, };
            
        case centreConstants.EDIT_STAFF_SUCCESS:
            let staff = state.staff.map(s => {
                if (s.id === action.id) return Object.assign({}, s, action.data);
                return s;
            })
            return { ...state, staff }

		case centreConstants.CREATE_REPORT_SUCCESS:
			state.reports.push(action.report.data.report);
			return { ...state, };

		case centreConstants.UPDATE_REPORT_SUCCESS:
			const reports = state.reports;
			const report = _.find(reports, { id: action.report.id });
			report.comment = action.report.comment;
			return { ...state, };

		case centreConstants.SEND_REPORT_REQUEST:
			return { ...state, reportSending: true };

		case centreConstants.SEND_REPORT_SUCCESS:
		case centreConstants.SEND_REPORT_FAILURE:
			return { ...state, reportSending: false };

		default:
			return state;
	}
}