import React, { Component } from 'react';
import { connect } from 'react-redux';

import { isAdmin } from '../helpers/user';

import { CentreList } from '../components';
import { centreActions } from '../actions';

class Centres extends Component {
  componentDidMount() {
    const { getCentres } = this.props;
    getCentres();
  }

  render() {
    const { centres, user } = this.props;
    const adminUser = isAdmin(user);

    return (
      <div className="container">
        <div className="column">
          <h1>Test Centres</h1>
        </div>
        <div className="column">
          <CentreList centres={centres} adminUser={adminUser} />
        </div>
      </div>
    );
  }
}

function mapStateToProps({ centres, user }) {
  return { centres, user };
}

export default connect(mapStateToProps, {
  getCentres: centreActions.getCentres
})(Centres);