import React from 'react';
import _ from 'lodash';
import moment from 'moment';

import { getDurationStatus } from '../helpers/ragStatuses';

import { Button, MonthSelector } from './';

import symbols from '../media/svg-symbols.svg';

const getResultStatus = (result) => {
	//   if (result === 'FAILED') return 'red';
	//   if (result === 'ABORTED') return 'orange';
	if (result === 'ABORTED') return 'red';
	return '';
}

export const RawData = ({ title, open, handleClose, tests, selectedMonth, setMonth }) => {
	if (!tests || !tests.all) {
		return null;
	}

	const monthData = tests.all[selectedMonth];

	return (
		<div className={`overlay ${open ? 'open' : ''}`}>
			<div className="overlay__header">
				<Button text={<svg className="icon-cross"><use xlinkHref={`${symbols}#icon-cross`}></use></svg>} onClick={handleClose} />
				<MonthSelector selectedMonth={selectedMonth} onChange={setMonth} />
				{title && <h2>{title}</h2>}
			</div>
			<div className="overlay__content">
				<table>
					<thead>
						<tr>
							<th>Site Number</th>
							<th>Test date/time</th>
							<th>Test Number</th>
							<th>Registration</th>
							<th>VIN</th>
							<th>Make</th>
							<th>Model</th>
							<th>Class</th>
							<th>User Id</th>
							<th>Test type</th>
							<th>Result</th>
							<th>Reason for aborting</th>
							<th>Test Duration</th>
							<th>Tester who recorded test</th>
							<th>Date/time of recording CT test</th>
							<th>Contingency Test Reason</th>
							<th>Contingency Code</th>
							<th>Login type at start</th>
							{/* <th>Login type at completion</th> */}
							{/* <th>Client IP address at start</th> */}
							<th>Client IP address at completion</th>
							{/* <th>Browser agent at start</th>
              <th>Browser agent at completion</th>
              <th>Cookie code at start</th>
			<th>Cookie code at completion</th> */}
							<th>brake_test_type</th>
							<th>entry_type</th>
						</tr>
					</thead>
					<tbody>
						{_.map(monthData, (row, index) => (
							<tr key={index}>
								<td title={row.site_number}>{row.site_number}</td>
								<td title={moment(row.test_date).format('DD/MM/YYYY HH:mm')}>{moment(row.test_date).format('DD/MM/YYYY HH:mm')}</td>
								<td title={row.test_number}>{row.test_number}</td>
								<td title={row.registration}>{row.registration}</td>
								<td title={row.vin}>{row.vin}</td>
								<td title={row.make}>{row.make}</td>
								<td title={row.model}>{row.model}</td>
								<td title={row.class}>{row.class}</td>
								<td title={row.userId}>{row.userId}</td>
								<td title={row.test_type}>{row.test_type}</td>
								<td title={row.result} className={getResultStatus(row.result)}>{row.result}</td>
								<td title={row.abortion_reason}>{row.abortion_reason}</td>
								<td title={row.test_duration} className={getDurationStatus(row.test_duration)}>{row.test_duration}</td>
								<td title={row.tester_recording}>{row.tester_recording}</td>
								<td title={row.recording_date}>{row.recording_date}</td>
								<td title={row.contingency_reason}>{row.contingency_reason}</td>
								<td title={row.contingency_code}>{row.contingency_code}</td>
								<td title={row.login_type_start} className={row.login_type_start !== '2fa_pin' ? 'red' : ''}>{row.login_type_start}</td>
								{/* <td title={row.login_type_completion} className={row.login_type_start !== '2fa_pin' ? 'red' : ''}>{row.login_type_completion}</td> */}
								{/* <td title={row.client_ip_start}>{row.client_ip_start}</td> */}
								<td title={row.client_ip_completion}>{row.client_ip_completion}</td>
								{/* <td title={row.browser_start}>{row.browser_start}</td>
                <td title={row.browser_completion}>{row.browser_completion}</td>
                <td title={row.cookie_start}>{row.cookie_start}</td>
                <td title={row.cookie_completion}>{row.cookie_completion}</td> */}
								<td title={row.brake_test_type}>{row.brake_test_type}</td>
								<td title={row.entry_type}>{row.entry_type}</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	);
};