import React, { Component } from 'react';
import { connect } from 'react-redux';
import { settingsActions } from '../actions';

class SplitUserDataToggle extends Component {
	render() {

		const { toggleSplitUserData, splitUserData } = this.props;

		return (
			<div className="splitUserData" style={(this.props.style || {})}>
				<input
					checked={splitUserData}
					type="checkbox"
					id="splitUserDataCheckbox"
					onChange={toggleSplitUserData} 
					style={{
						marginRight: 10
					}}
				/>
				<label htmlFor="splitUserDataCheckbox">Split tester data.</label>
			</div>
		)
	}
}


function mapStateToProps({ settings }) {
	return { splitUserData: settings.splitUserData };
}

export default connect(mapStateToProps, {
	toggleSplitUserData: settingsActions.toggleSplitUserData
})(SplitUserDataToggle);