import React, { Component } from 'react';
import { connect } from 'react-redux';

import { userActions } from '../actions';
import { Button } from '../components';
import { validateEmail } from '../helpers/validation';

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      pwd: '',
      emailValid: true,
      submitted: false
    };
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    const { email } = this.state;

    if (!!email && name === 'email') {
      const emailValid = validateEmail(value);
      this.setState({ [name]: value, emailValid });
    } else {
      this.setState({ [name]: value });
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { login } = this.props;
    const { email, pwd, emailValid } = this.state;

    this.setState({
      submitted: true
    });

    if (!email || !pwd || !emailValid) {
      return false;
    }

    login(email, pwd);
  }

  render() {
    const { user } = this.props;
    const { email, pwd, emailValid, submitted } = this.state;

    return (
      <div className="container">
        <div className="column login">
          <svg className="logo" viewBox="0 0 77.06 66.04">
            <path d="M45.31,38.94h31.2l-22.4-38.8l-15.6,27.02L22.91,0.15l-22.4,38.8h31.2h0.04l-15.65,27.1h44.8l-15.65-27.1
                H45.31z M54.12,15.74l8.89,15.4H45.22L54.12,15.74z M14.02,31.15l8.89-15.4l8.89,15.4H14.02z M29.62,58.24l8.89-15.4l8.89,15.4
                H29.62z"/>
          </svg>
          <h1>Login</h1>
          <form action="/" onSubmit={this.handleSubmit}>
            <div className="row">
              <div className="field">
                <input name="email" value={email} type="text" placeholder="Username" onChange={this.handleChange} />
                {(submitted && !email) && <span className="field-validation-error">Username is required</span>}
                {(submitted && !emailValid) && <span className="field-validation-error">Please enter a valid email address</span>}
              </div>
            </div>
            <div className="row">
              <div className="field">
                <input name="pwd" value={pwd} type="password" placeholder="Password" onChange={this.handleChange} />
                {(submitted && !pwd) && <span className="field-validation-error">Password is required</span>}
              </div>
            </div>
            <Button text="Login" type="submit" loading={user.loading} />
          </form>
        </div>
      </div>
    );
  }
}

function mapStateToProps({ user }) {
  return { user };
}

export default connect(mapStateToProps, {
  login: userActions.login
})(Login);