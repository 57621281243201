import React, { Component } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import moment from 'moment';
import SplitUserDataToggle from './SplitUserDataToggle';

export class LineChart extends Component {
	constructor(props) {
		super(props);
		const { title, data, valueFormatter, min, max, datetime } = this.props;
		const xAxis = datetime ? {
			type: 'datetime',
			labels: {
				formatter: function () {
					var d = moment(this.value);
					return `<b style="font-size: 1.1em; font-weight: 600;">${d.format('MMM')}</b><br/><span>${d.format('YYYY')}</span>`;
				},
				style: {
					fontFamily: '"Montserrat", sans-serif',
					fontSize: '14px',
					color: 'black'
				},
				y: 40,
				useHtml: true
			}
		} : {};

		this.state = {
			chartOptions: {
				title: {
					text: title,
					style: {
						fontFamily: '"Montserrat", sans-serif',
						color: 'black',
						fontWeight: 600
					}

				},
				legend: { enabled: false },
				credits: { enabled: false },
				xAxis,
				yAxis: {
					title: {
						enabled: false
					},
					min: min,
					max: max,
					labels: {
						formatter: valueFormatter ? function () { return valueFormatter(this.value) } : null,
						style: {
							fontFamily: '"Montserrat", sans-serif',
							fontSize: '12px',
							color: 'black',
							fontWeight: 600
						}
					}
				},
				tooltip: {
					style: {
						fontFamily: '"Montserrat", sans-serif',
						fontSize: '14px',
						color: 'black'
					},
					formatter: function () {
						var x = datetime ? moment(this.x).format('MMM YYYY') : this.x;
						if (!valueFormatter) {
							return `${this.series.name ? this.series.name + " - " : ""}${x}: <b>${this.y}</b>`;
						}
						return `${this.series.name ? this.series.name + " - " : ""}${x}: <b>${valueFormatter(this.y)}</b>`;
					}
				},
				series: props.series || [
					{
						name: title,
						data: data,
						color: '#7AC41A',
						lineWidth: 8
					}
				]
			}
		};
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.data !== this.props.data || prevProps.series !== this.props.series) {
			this.setState({
				chartOptions: {
					series: this.props.series || [
						{ data: this.props.data }
					]
				}
			});
		}
	}

	render() {
		const { chartOptions } = this.state;

		return (
			<>
				{/* {this.props.series ?
					<div className="users">
						{this.props.series.map(series => {

						})}
					</div> : null
				} */}
				<SplitUserDataToggle style={{ textAlign: "right" }} />
				<HighchartsReact
					highcharts={Highcharts}
					options={chartOptions}
				/>
			</>
		)
	}
}