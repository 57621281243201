import { testStatuses } from '../constants';
import { parseSeconds } from './formatters';

export const getDurationStatus = (duration) => {
    const { acceptable, greenTolerance, amberTolerance } = testStatuses.duration;

    const average = parseSeconds(duration);

    if ((average >= acceptable - greenTolerance) && average <= acceptable + greenTolerance) {
        return 'green';
    }
    if ((average >= acceptable - amberTolerance) && average <= acceptable + amberTolerance) {
        return 'orange';
    }
    return 'red';
}

export const getIPVariationStatus = (averageVariation) => {
    const { acceptable, tolerance } = testStatuses.ipVariation;

    const orangeLimit = acceptable + tolerance;

    if (averageVariation > orangeLimit) {
        return 'red';
    }
    if (averageVariation > acceptable) {
        return 'orange';
    }
    return 'green';
}

export const getLoginStatus = (average2FALogins) => {
    const { acceptable, tolerance } = testStatuses.logins;

    const badLogins = 100 - average2FALogins;
    const orangeLimit = acceptable * ((tolerance / 100) + 1);

    if (badLogins > orangeLimit) {
        return 'red';
    }
    if (badLogins > acceptable) {
        return 'orange';
    }
    return 'green';
}

export const getTestOutcomeStatus = (value, referenceValue) => {
    //   const { acceptable, tolerance } = testStatuses.outcomes;

    //   const passCount = testOutcomes.PASSED ? testOutcomes.PASSED.length : 0;
    //   const failCount = testOutcomes.FAILED ? testOutcomes.FAILED.length : 0;
    //   const abortCount = testOutcomes.ABORTED ? testOutcomes.ABORTED.length : 0;
    //   const testCount = passCount + failCount + abortCount;
    //   const percentageFail = ((failCount + abortCount) / testCount) * 100;

    //   const orangeLimit = acceptable * ((tolerance / 100) + 1);

    //   if (percentageFail > orangeLimit) {
    //     return 'red';
    //   }
    //   if (percentageFail > acceptable) {
    //     return 'orange';
    //   }
    //   return 'green';

    if (value === referenceValue) return "";

    let percentageDiff = (Math.abs(value - referenceValue) / referenceValue) * 100;

    if (percentageDiff >= 50) {
        return "red";
    } else if (percentageDiff >= 25) {
        return "orange";
    } else {
        return "green";
    }
}

export const getTestVariationStatus = (variation) => {
    const { acceptable, tolerance } = testStatuses.variation;

    const orangeLimit = acceptable * ((tolerance / 100) + 1);

    if (variation > orangeLimit) {
        return 'red';
    }
    if (variation > acceptable) {
        return 'orange';
    }
    return 'green';
}