import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import { getLoginStatus } from '../helpers/ragStatuses';
import { TestDetail, LineChart } from '.';
import { getChartDataByUserId } from '../helpers/misc';

function get2FAPercentage(tests) {
	if (!tests || !tests.length) {
		return 0;
	}
	let goodLogins = 0;
	let badLogins = 0;
	tests.forEach(test => {
		test.login_type_start === '2fa_pin' ? goodLogins++ : badLogins++;
		test.login_type_completion === '2fa_pin' ? goodLogins++ : badLogins++;
	});
	const totalLogins = goodLogins + badLogins;
	return (goodLogins / totalLogins) * 100;
}

export class TestLogins extends Component {
	render() {
		const { tests, index, selectedIndex, selectTestDetail, splitUserData } = this.props;
		const open = selectedIndex === index;
		const loginPercentage = get2FAPercentage(tests.month);

		return (
			<Fragment>
				<TestDetail
					title="Security Card Logins"
					value={`${loginPercentage.toFixed(1)}%`}
					status={getLoginStatus(loginPercentage)}
					open={open}
					onClick={() => selectTestDetail(index)}
				/>
				{open && <TestLoginDetails tests={tests.all} splitUserData={splitUserData} />}
			</Fragment>
		)
	}
}

const TestLoginDetails = ({ tests, splitUserData }) => {

	let series;

	if (splitUserData) {
		series = getChartDataByUserId(tests, getChartData);
	} else {
		series = [
			{
				name: "Security Card Logins",
				data: getChartData(tests),
				lineWidth: 8,
				color: "#7AC41A"
			}
		];
	}

	return (
		<section className="centre-expanded-detail">
			<LineChart series={series} valueFormatter={value => `${value.toFixed(1)}%`} min={0} max={100} datetime />
		</section>
	);
}

function getChartData(tests) {
	const data = _.map(tests, (value, key) => {
		const average = get2FAPercentage(value);
		return { x: parseInt(key), y: average };
	});
	return data;
}