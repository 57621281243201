import React from 'react';
import { Link } from 'react-router-dom';

import symbols from '../media/svg-symbols.svg';

export const Centre = ({ centre }) => {
  const { id, name, disabled } = centre;

  return (
    <Link to={`/centre/${id}`} className={`centre-list__item centre-list__item--green centre-list__item--${disabled ? 'disabled' : 'enabled'}`}>
      <h2>
        <svg className="icon-house"><use xlinkHref={`${symbols}#icon-house`}></use></svg>
        {name}
      </h2>
      <span className="btn">View Test Centre</span>
    </Link>
  );
}
